import { userStore } from "@/mobx/rootStore";
import { Group, Loader, Menu, Paper, Select, Text, ThemeIcon } from "@mantine/core";
import { IconTrash, IconUser, IconUserBolt, IconUserShield } from "@tabler/icons-react";
import classes from "./CrewMember.module.css";

export default function CrewMember({
	crewMember = {},
	onChangeRole = () => {},
	isLoading = false,
}) {
	const myUsername = userStore?.data?.username;
	const memberRole = crewMember.role;

	let memberIcon = <IconUser />;

	if (memberRole === "owner") memberIcon = <IconUserBolt color="var(--mantine-color-yellow-4)" />;
	if (memberRole === "admin") memberIcon = <IconUserShield color="var(--mantine-color-cyan-4)" />;

	const itsMe = myUsername === crewMember?.username;

	return (
		<Menu shadow="md" width={200}>
			<Paper className={classes.target} withBorder p="xs">
				<Group justify="space-between">
					<Group>
						{memberIcon}
						<Text>
							{crewMember?.username}{" "}
							{itsMe && (
								<Text span c="dimmed">
									{"(tú)"}
								</Text>
							)}
						</Text>
					</Group>

					{isLoading && <Loader />}

					<Select
						label=""
						placeholder="Rol"
						value={memberRole}
						onChange={(value) =>
							onChangeRole({ userId: crewMember?.id, user: crewMember, value })
						}
						disabled={memberRole === "owner" || isLoading}
						data={[
							{ value: "owner", label: "Propietario", disabled: true },
							{ value: "admin", label: "Administrador" },
							{ value: "member", label: "Miembro" },
							{ value: "#delete", label: "Expulsar" },
						]}
						renderOption={({ option }) =>
							option.value === "#delete" ? (
								<Group gap="xxxs">
									<ThemeIcon size="sm" variant="transparent" color="red.5">
										<IconTrash />
									</ThemeIcon>
									<Text c="red" size="sm">
										{option.label}
									</Text>
								</Group>
							) : (
								option.label
							)
						}
						allowDeselect={false}
						checkIconPosition="left"
						w={150}
					/>
				</Group>
			</Paper>
		</Menu>
	);
}
